@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700&display=swap');

.profile-page {
  font-family: 'Nunito Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f7fafc;
}

.profile-header {
  position: relative;
  height: 300px;
 background-color: #800020;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic-container {
  position: relative;
  margin-top: 2rem; /* Added margin to the top */
}

.profile-pic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid white;
}

.profile-details {
  margin-top: -75px;
  padding: 2rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.user-info {
  text-align: center;
  margin-bottom: 2rem;
}

.user-name {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 60px;
  color: #2d3748;
}

.username-button {
  background-color: #800020;
  color: #ffffff;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  border: none;
  cursor: default;
  text-transform: uppercase;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
}

/* .username-button:hover {
  background-color: #6d0016;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.stats-enhanced {
  display: flex;
  justify-content: space-between; /* Space out the stats */
  align-items: center;
  padding: 1.5rem 2rem; /* Add more padding for space */
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
  border-radius: 12px; /* Slightly rounded corners */
  border: 1px solid #e2e8f0; /* Light border */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.stat-enhanced {
  text-align: center;
  flex: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-enhanced:not(:last-child) {
  border-right: 1px solid #e2e8f0; /* Add subtle dividing lines between stats */
  padding-right: 2rem; /* Adjust padding for spacing */
}

.stat-number {
  font-size: 3.5rem; /* Larger font size for the number */
  font-weight: 900; /* Bolder weight */
  color: #1a202c; /* Darker text */
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); /* Stronger text shadow */
}

.stat-label {
  font-size: 1.5rem; /* Larger label font */
  font-weight: 600; /* Semi-bold font */
  color: #718096; /* Gray text */
}



.info-box {
  background-color: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  padding: 1.5rem; /* Padding inside the box */
  margin-top: 2rem; /* Space above the box */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.info-item {
  flex: 1 1 45%; /* Flex basis of 45% allows two items per row on larger screens */
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Space between items */
}

.info-item .icon {
  font-size: 1.75rem; /* Size of the icons */
  color: #4a5568; /* Icon color */
  margin-right: 0.75rem; /* Space between icon and text */
}

.info-item span {
  font-size: 1.25rem; /* Text size */
  font-weight: 600; /* Semi-bold text */
  color: #2d3748; /* Text color */
}
@media (max-width: 768px) {
  .stats-enhanced {
    flex-direction: column;
  }

  .stat-enhanced:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #e2e8f0;
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .info-item {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin-bottom: 1rem;
  }

  .info-item .icon {
    font-size: 1.5rem; /* Slightly smaller icons on smaller screens */
  }

  .info-item span {
    font-size: 1.125rem; /* Slightly smaller text on smaller screens */
  }

  .profile-pic {
    width: 120px;
    height: 120px;
  }

  .user-name {
    font-size: 2rem;
  }

  .stat-number {
    font-size: 3rem;
  }

  .stat-label {
    font-size: 1.25rem;
  }


}

@media (max-width: 480px) {
  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .user-name {
    font-size: 1.75rem;
  }

  .username-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .stat-number {
    font-size: 2.5rem;
  }

  .stat-label {
    font-size: 1rem;
  }


  .info-item .icon {
    font-size: 1.25rem; /* Smaller icons on very small screens */
  }

  .info-item span {
    font-size: 1rem; /* Smaller text on very small screens */
  }
}
