/* Navbar.css */
.navbar-custom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: rgb(242, 238, 238);
    /* background-color: #800020; */
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);  */
  }
  
  /* .navbar-custom::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(10px);
    z-index: -1;
  } */

  /* Remove all outlines, borders, and shadows on focus, active, and hover states */
/* Target the specific button to remove all unwanted styles */
.no-border {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Remove all unwanted styles on hover, focus, and active states */
.no-border:focus,
.no-border:active,
.no-border:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

/* Remove the tap highlight on mobile devices */
.no-border {
  -webkit-tap-highlight-color: transparent;
}

/* Additionally target the container if needed */
.navbar-custom button:focus,
.navbar-custom button:active,
.navbar-custom button:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
