.business-hours {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1.2px solid #e0e0e0;
  border-radius: 5px;
  padding: 7px !important;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.days-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  margin-top: 5% !important;
}

.day-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.time-selector {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.time-row {
  display: flex;
  align-items: center;
  gap: 1px;
  padding: 5px !important;
  justify-content: flex-start;
  height: 50px;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
}

.day-label {
  width: 80px;
  text-align: right;
  padding-right: 10px;
  line-height: 1.5;
  flex-shrink: 0;
}

.time-select {
  width: calc(50% - 25px);
  padding: 5px !important;
  font-size: 1rem;
  border: 2px solid #e0e0e0;
  background-color: #800020;
  color: white;
  border-radius: 5px;
  box-sizing: border-box;
  line-height: 1.5;
}

.time-select option {
  padding: 5px;
}

.time-to {
  width: 40px;
  text-align: center;
  line-height: 1.5;
  flex-shrink: 0;
}

.time-select.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 0.5em;
}

.apply-to-all {
  margin-top: 20px;
  text-align: center;
  
}

.apply-button {
  background-color: #800020; /* Green */
  color: white;
  border: none;
  padding: 5px !important;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #570016; /* Darker green on hover */
}

/* Responsive adjustments for tablets and smaller screens */
@media (max-width: 768px) {
  .time-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    
    height: auto;
    flex-wrap: wrap;
  }

  .day-label {
    width: 100%;
    text-align: left;
    padding-right: 0;
    margin-bottom: 5px;
  }

  .time-select {
    width: 100%;
    max-width: none;
  }

  .time-to {
    display: none;
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
  .days-selector {
    justify-content: flex-start;
    margin-top: 20px !important;
    gap: 5px;
  }

  .day-checkbox {
    font-size: 0.85rem;
  }

  .time-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    margin-left: 7.5px !important;
    padding: 5px !important;
    width: 95%;
  }

  .time-select {
    width: 100%;
    max-width: none;
    padding: 5px !important;
  }
}
