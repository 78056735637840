.multi-select-options {
    display: none;
  }
  
  .multi-select-dropdown.open + .multi-select-options {
    display: block;
  }

  .multi-select {
    position: relative;
    width: 100%;
  }
  
  .form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #800020;
  }
  
  .multi-select-dropdown {
    width: 100%;
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    font-size: 1rem;
    background-color: white;
    cursor: pointer;
    position: relative;
  }
  
  .multi-select-dropdown::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px; /* Adjusted size based on your preference */
    height: 15px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
  }
  
  .multi-select-dropdown.open::after {
    transform: translateY(-50%) rotate(180deg);
  }
  
  .multi-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 2px solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    padding: 10px;
  }
  
  .multi-select-option {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .multi-select-option input {
    margin-right: 10px;
   
  }
  
  .multi-select-option label {
    margin: 0;
  }
  