.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .otp-card {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: center;
  }
  
  .otp-title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .otp-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .form-field {
    position: relative;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #007bff;
  }
  
  .form-input:focus + .form-label {
    color: #800020;
  }
  
  .form-input:focus + .form-label,
  .form-input:not(:placeholder-shown) + .form-label {
    top: -10px;
    left: 10px;
    font-size: 0.8rem;
    background-color: white;
    padding: 0 5px;
  }
  
  .form-label {
    position: absolute;
    top: -10px;
    left: 15px;
    background: #fff;
    padding: 0 5px;
    font-size: 0.875rem;
    color: #777;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .submit-button, .resend-button {
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover, .resend-button:hover {
    background-color: #0056b3;
  }
  
  .resend-button {
    background-color: #6c757d;
  }
  
  .resend-button:hover {
    background-color: #5a6268;
  }
  