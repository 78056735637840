.privacy-main-container {
    padding: 40px;
    max-width: auto;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .privacy-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-header h1 {
    color: #800020;
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .privacy-header p {
    font-size: 1.2em;
    color: #666;
    margin-top: 10px;
  }
  
  .privacy-section {
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .privacy-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .privacy-section h2 {
    color: #333;
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #800020;
    padding-bottom: 5px;
  }
  
  .privacy-section p {
    line-height: 1.8;
    color: #666;
    font-size: 1.1em;
    margin-bottom: 15px;
  }
  
  .privacy-footer {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9em;
    color: #999;
    border-top: 1px solid #ddd;
    padding: 0; /* Remove all padding */
}
