.forgotPasswordPage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #800020aa 0%, #800020 100%);
    padding: 20px;
  }
  
  .forgotPasswordPage-card {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
    transition: transform 0.3s ease;
  }
  
  .forgotPasswordPage-card:hover {
    transform: translateY(-10px);
  }
  
  .forgotPasswordPage-title {
     font-size: 34px;
    color: #800020;
    margin-bottom: 10%;
    
    font-family: "Baskervville, serif" 
  }
  
  .forgotPasswordPage-toggle-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .forgotPasswordPage-toggle-label {
    margin: 0 10px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
    color: #333;
    border: 1px solid #800020;
  }
  
  .forgotPasswordPage-toggle-label--active {
    background-color: #800020;
    color: white;
  }
  
  .forgotPasswordPage-input-field {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .forgotPasswordPage-input-field:focus {
    border-color: #800020;
    outline: none;
  }
  
  .forgotPasswordPage-error {
    color: red;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .forgotPasswordPage-btn {
    background-color: #800020;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Added for centering loader */
  }
  
  .forgotPasswordPage-btn .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #800020;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute; /* Added for centering loader */
  }
  
  
  .forgotPasswordPage-btn:hover {
    background-color: #4d0013;
    transform: translateY(-3px);
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin-right: 10px; /* Add margin to the right */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }