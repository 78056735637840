/* styles.css */

.container-cont {
    background: #fff;
    width: 70%;
    max-width: 600px; 
    border-radius: 24px;
    box-shadow: 16px 16px 8px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.picture-container-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Spacing between image and form */
}

.picture-cont img {
    max-width: 100%; /* Ensure image responsiveness */
    height: auto;
}

.contact-form-container-cont {
    width: 100%;
}

.contact-form-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form-header-cont {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 20px; /* Space below header */
}

.email-input-cont,
.message-cont {
    border: none;
    outline: none;
    background: #eee;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 15px; /* Space between inputs */
}

.email-input-cont {
    width: 100%;
}

.message-cont {
    width: 100%;
    resize: vertical; /* Allow vertical resizing of textarea */
}

.submit-cont {
    border: none;
    outline: none;
    color: #fff;
    padding: 15px;
    background: #800020;
    /* style={{ color: "#800020" }} */
    font-size: 100%;
    font-weight: 600;
    border-radius: 6px;
    transition: transform 300ms 0s cubic-bezier(0, 0.23, 0.29, 2.45);
    cursor: pointer;
}

.submit-cont:hover {
    background: #69011b;
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .container-cont {
        padding: 12px;
       
        /* Adjust padding for smaller screens */
    }

    .picture-container-cont {
        width: 100%;
    }

    .form-header-cont {
        font-size: 1.5em;
    }

    .email-input-cont,
    .message-cont {
        padding: 12px; /* Adjust input padding */
    }

    .submit-cont {
        padding: 12px; /* Adjust button padding */
    }
}
