.winery-profile-page {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
}

.profile-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
  margin-top: 80px;
  overflow: hidden;
  transition: all 0.3s ease;
}



.profile-card:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

.header-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  position: relative;
  object-position: center;
  z-index: 1;
  
}

.profile-content {
  padding: 30px;
}
.business-hour-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px; /* Add some space between each line */
}

.business-day {
  font-weight: bold;
  flex: 0 0 40%; /* Adjust width to ensure space for time */
}

.business-time {
  flex: 1;
  text-align: right;
}


.profile-picture {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  border: 6px solid white;
  margin: -90px auto 20px;
  display: block;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;  /* Ensure the profile picture can be positioned above */
  z-index: 2;
}

.profile-title {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
  font-weight: 700;
}

.profile-subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
  text-align: center;
}

.profile-section {
  margin-bottom: 40px;
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 10px;
}


.section-title {
  font-size: 1.8em;
  margin-bottom: 25px;
  color: #444;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  font-weight: 600;
}
.profile-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 1.1em;
}


.field-label {
  font-weight: 600;
  color: #555;
  flex-basis: 30%;
}

.field-value {
  color: #333;
  flex-basis: 65%;
  text-align: start;
  word-break: break-word;
}

.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.profile-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}
.logo-preview-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.small-logo {
  max-width: 100px; /* You can adjust the size */
  max-height: 100px; /* You can adjust the size */
  object-fit: contain;
}

.remove-logo-button {
  background-color: #800020;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}


.logout-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  text-align: center;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c9302c;
}

.edit-button {
  margin: 30px auto;
  padding: 12px 25px;
  background-color: #800020;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.1em;
  display: block;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background-color: #600010;
  transform: translateY(-2px);
}

.edit-form {
  margin: 20px 0;
}

.form-field {
  margin-bottom: 20px;
  margin-top: 0;
}


.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: #800020;
  outline: none;
}

.delete-icon:hover {
  color: #800028 !important;
}

.button-style {
  background-color: #1976d2;
  width: 10%;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.3s ease;
}

.button-style:hover {
  background-color: #145a86; /* Optional hover color */
  transform: translateY(-5px); /* Move button slightly upwards */
}


.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.submit-button:hover {
  background-color: #218838;
}

.error-message {
  color: #800020;
  font-size: 0.9em;
  margin-top: 5px;
}

.show-direction-button {
  background-color: #800020;
  color: #fff;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 0.9em;
  transition: all 0.3s ease;
  margin-top: 5px;
  display: inline-block;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  position: relative;
  margin: 5px;
  width: 100px;
  height: 100px;
}

.image-preview i{
  background-color: none;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #800020;
  border-radius: 5px;
}




.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #800020; /* Change background on hover */
  color: white; /* Change text color on hover */
  border: 2px solid #800020; /* A bold border to give it more emphasis */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2; /* Ensure it stays on top of the image */
  font-size: 1.2rem; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
  transition: background 0.3s ease, transform 0.3s ease; /* Add a smooth hover effect */
}

.remove-image-button:hover {

  transform: scale(1.1); /* Slightly enlarge on hover */
}


.view-image-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the icon */
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2; /* Ensure it stays on top of the image */
}

.view-image-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.image-modal {
  background: white;
  padding: 0;
  border-radius: 10px;
  max-width: 90vw; /* Use viewport width to ensure it scales well */
  max-height: 90vh; /* Use viewport height to ensure it scales well */
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.image-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75); /* Darken the background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.close-modal-button {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px; /* Reduced width */
  height: 30px; /* Reduced height */
  font-size: 1.5rem; /* Slightly larger font size for the icon */
  color: white;
  background-color: #800020;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  z-index: 1001;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Vertically center the icon */
  justify-content: center; /* Horizontally center the icon */
  padding: 0; /* Remove any default padding */
  line-height: 1; /* Ensure there's no extra space around the icon */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
}

.close-modal-button i {
  margin-bottom: -8px !important; /* Adjusted to move the icon further up */
}

.close-modal-button:hover {
  background-color: #a93c57; /* Lighter shade on hover */
  transition: background-color 0.3s ease; /* Smooth transition for color change */
}




.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image maintains aspect ratio */
  display: block;
  margin: auto;
}

.show-direction-button:hover {
  background-color: #600010;
  transform: translateY(-2px);
}

.status-button {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  font-size: 0.9em;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-pending .status-circle {
  background-color: #ffa500;
}

.status-ready-to-approve .status-circle {
  background-color: #ffd700;
}

.status-approved .status-circle {
  background-color: #32cd32;
}

@media (max-width: 768px) {
  .profile-card {
    margin-top: 60px;
  }

  .profile-picture {
    width: 150px;
    height: 150px;
    margin: -75px auto 15px;
  }

  .profile-title {
    font-size: 2em;
  }

  .profile-subtitle {
    font-size: 1.1em;
  }

  .section-title {
    font-size: 1.5em;
  }

  .profile-field {
    flex-direction: column;
    align-items: flex-start;
  }

  .field-label {
    margin-bottom: 5px;
  }

  .field-value {
    text-align: left;
  }

  .profile-image {
    height: 150px;
  }

  .edit-button,
  .submit-button {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .business-hour-line {
    flex-direction: column;
    align-items: flex-start;
  }

  .business-day,
  .business-time {
    flex: 0 0 100%; /* Full width for each */
    text-align: left; /* Align text to the left for readability */
  }

  .business-time {
    margin-top: 5px; /* Space between day and time on mobile */
  }
}
