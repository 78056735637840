/* components/Alert.css */
.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .alert {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
  }
  
  .alert h2 {
    margin-bottom: 10px;
  }
  
  .alert p {
    margin-bottom: 20px;
  }
  
  .alert-button {
    background-color: #800020;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .alert-button:hover {
    background-color: #600016;
  }
  
  .alert-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #aaa;
  }
  
  .alert-close:hover {
    color: #666;
  }
  
  .alert.success {
    border-left: 4px solid #28a745;
  }
  
  .alert.error {
    border-left: 4px solid #dc3545;
  }
  
  .alert.info {
    border-left: 4px solid #17a2b8;
  }
  
  .alert.warning {
    border-left: 4px solid #ffc107;
  }
  
  .store-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .store-icona {
    width: 160px;
   
  }
  .store-iconb {
    width: 132px;
   margin-top: 8px;
   
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  