/* assets/styles/spinner.css */
.spinner-container {
    /* position: fixed; */
    top: 50;
    left: 50;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Optional: to cover the entire page */
    z-index: 9999; /* To make sure it stays on top */
  }
  
  .spinner {
    /* border: 16px solid #f3f3f3;  */
    /* border-top: 16px solid #3498db;  */
    /* border-radius: 50%; */
    width: 40px;
    height: 90px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  