.full-page-background {
  background-image: url('https://res.cloudinary.com/dc8piabne/image/upload/v1721898874/bdvkdw8dbqgr2k6m3lnt.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  margin-top: 60px;
  flex-direction: column;
  color: #fff;
  padding: 20px; /* Add padding for better spacing on mobile */
}

.contact-dotts-trails {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: contact-dotts-trails 1s infinite linear;
}

@keyframes contact-dotts-trails {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.contact-input-contactus,
.contact-message-contactus {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.contact-submit-contact {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-submit-contact-normal {
  background-color: #42482d;
}

.contact-submit-contact-loading {
  background-color: #cbd5e0;
  cursor: not-allowed;
}

.contact-submit-contact:hover:not(:disabled) {
  background-color: #1a202c;
}

.contact-error-text {
  color: #f56565;
  margin-top: -10px;
  margin-bottom: 10px;
}

.contact-success-text {
  color: #48bb78;
  margin-top: -10px;
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.container-cont.flex {
  display: flex;
}

.container-cont.flex-wrap {
  flex-wrap: wrap;
}

.container-cont .-mx-3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.container-cont .mb-2 {
  margin-bottom: 0.5rem;
}

.container-cont .w-full {
  width: 100%;
}

.container-cont .md\:w-1\/2 {
  width: 50%;
}

.container-cont .px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.contact-container-cont,
.success-container {
  max-width: 600px;
  min-height: 600px; /* Ensure a minimum height */
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.picture-container-cont .picture-cont p {
  color: #800020;
  text-align: center;
  font-size: larger;
  font-weight: 700;
  letter-spacing: 1px;
}

.contact-picture img {
  max-width: 100px;
  border-radius: 50%;
}

.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.contact-form {
  width: 100%;
}

.email-input-cont {
  color: #1a202c;
}

.custom-contact-heading-unique {
  color: #49171A;
  margin-top: 130px;
  text-align: center;
  font-size: 4rem;

  @media (min-width: 640px) {
    font-size: 5rem;
  }

  @media (min-width: 768px) {
    font-size: 5rem;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;
  }

  @media (min-width: 1280px) {
    font-size: 5rem;
  }

  @media (min-width: 1536px) {
    font-size: 6rem;
  }
}

.email-input-cont, .message-cont {
  border-radius: 18px !important;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 100%;
  color: #1a202c;
  background-color: #e7d3ae !important;
  box-sizing: border-box;
}

.submit-cont {
  background-color: #e7d3ae !important;
  color: #800020 !important;
  font-weight: 500 !important;
  width: 100%; /* Ensure button takes full width on mobile */
  max-width: 290px;
  padding: 1 !important;
  border-radius: 20px !important;
  margin: 0 auto;
}

.submit-cont:disabled {
  background-color: #e7d3ae !important;
  color: #800020 !important;
}


.luvk{
  margin-bottom: 1rem;
  border-radius: 18px !important;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 46%;
  height: 55px;
  color: #1a202c;
  background-color: #e7d3ae !important;
  box-sizing: border-box;
}
.email-input-cont {
  margin-bottom: 1rem;
}

.message-cont {
  margin-bottom: 1rem;
}

.container-cont {
  background-color: rgba(195, 195, 195, 0.686) !important;
  border-radius: 8px !important;
  backdrop-filter: blur(1px);
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(1px);
  padding: 10px; /* Added padding for better spacing */
}

.success-text {
  color: #800020; /* Ensure the success message text is visible */
  font-size: 1.5rem; /* Adjust font size for better visibility */
}


/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 640px) {
  .contact-form-cont {
    display: flex;
    flex-direction: column;

    width: 95%; /* Increase the width of the form container */
    margin: 0 auto; /* Center the form on the screen */
  }

  

  .contact-form-container-cont {

    width: 95% !important; /* Increase the width of the form container */
    margin: 0 auto; /* Center the container */
  }

  .contact-form-cont .flex-wrap {
    flex-direction: column;
    width: 100%;
  }

  .w-full {
    width: 100% !important;
  }

  .px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }

  .email-input-cont,
  .message-cont,
  .submit-cont {
    width: 100% !important; /* Ensure full width for inputs and button */
    max-width: 100%; /* Prevent any width constraints */
  }

  .submit-cont {
    margin: 0 auto; /* Center the submit button */
    width: 100%;
  }

  .container-cont,
 .success-container {
    width: 100% !important; /* Increase the width of the form container */
    max-width: none; /* Remove any max-width constraints */
    padding: 10px; /* Ensure padding for better spacing */
    margin: 0 auto; /* Center the container */
    
  }

  .custom-contact-heading-unique {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }


}



