@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');

.register-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.register-container {
  font-family: 'Raleway', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
  margin-top: 50px;
  padding-bottom: 30px;
  /* margin-bottom: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.character-count {
  font-size: 0.8em;
  color: #666;
  text-align: right;
  margin-top: 5px;
}/* Style for the photo preview container */
.photo-preview-container {
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

/* Style for the photo preview image */
.photo-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* Style for the remove photo button */
.remove-photo-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-photo-button:hover {
  background-color: rgba(255, 0, 0, 1);
}

.remove-photo-button i {
  font-size: 14px; /* Adjust the icon size */
}


.register-card {
  background: white;
  margin-top: 50px; /* Adjusted margin for better centering */
  max-width: 600px;
  width: 100%;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.register-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Adjusted gap for better spacing */
}

.form-field,
.form-group {
  position: relative;
}

.multi-select-container {
  margin-bottom: 15px;

}

.multi-select-container select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  /* border: 1px solid red; */
  border-radius: 5px;
  
}

.multi-select-container label{
 
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.selected-option {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.selected-option button {
  background: none;
  border: none;
  color: #888;
  margin-left: 5px;
  cursor: pointer;
}

.form-label {
  position: absolute;
  left: 15px;
  top: 13px;
  color: #999;
  font-size: 1rem;
  transition: all 0.3s ease;
  pointer-events: none;
}
.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label,
.form-select:focus + .form-label,
.form-select:not(:placeholder-shown) + .form-label,
.form-group input:focus + .form-label,
.form-group input:not(:placeholder-shown) + .form-label,
.form-group select:focus + .form-label,
.form-group select:not(:placeholder-shown) + .form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  background-color: white;
  padding: 0 5px;
  color: #800020;
}
.form-group-label{
  position: absolute;
  left: 15px;
  top: -13px;
  z-index: 999;
  color: #999;
  font-size: 1rem;
  transition: all 0.3s ease;
  pointer-events: none;
}

/* =================== */
.custom-form-field {
  position: relative;
  margin-bottom: 1em;
  font-family: 'Raleway', sans-serif;
}

.custom-form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-family: 'Raleway', sans-serif;
}

/* Default label style */
.custom-form-label {
  position: absolute;
  left: 15px;
  top: 13px;
  color: #777777;
  /* Black color initially */
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  pointer-events: none;
  font-family: 'Raleway', sans-serif;
}

/* Change label color and position on focus or if input has text */
.custom-form-input:focus + .custom-form-label,
.custom-form-input:not(:placeholder-shown) + .custom-form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  background-color: white;
  font-weight: 600;
  padding: 0 5px;
  color: #800020; /* Red color when focused */
}

/* Reset label color when input is not focused and has no value */
.custom-form-input:not(:focus):not(:placeholder-shown) + .custom-form-label {
  color: #777777;
  /* Black color when not focused */
  font-weight: 600;
}

/* Error message style */
.custom-error-message {
  color: #800020;
  font-size: 12px;
  margin-top: 4px;
  font-family: 'Raleway', sans-serif;
}
/* =================== */
.form-input,
.form-select,
.form-group input,
.form-group select {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: white;
}

.form-select,
.form-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'%3E%3Cpath fill='gray' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
  padding-right: 40px;
}

.form-input:focus,
.form-select:focus,
.form-group input:focus,
.form-group select:focus {
  border-color: #800020;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label,
.form-select:focus + .form-label,
.form-select:not(:placeholder-shown) + .form-label,
.form-group input:focus + .form-label,
.form-group input:not(:placeholder-shown) + .form-label,
.form-group select:focus + .form-label,
.form-group select:not(:placeholder-shown) + .form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  background-color: white;
  padding: 0 5px;
  color: #800020;
}

.error-message {
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 5px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.submit-button {
  background: linear-gradient(45deg, #800020, #630119);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  opacity: 0.7;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(45deg, #630119, #800020);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.submit-button:disabled {
  cursor: not-allowed;
}

.submit-button:not(:disabled) {
  opacity: 1;
}

.toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Adjusted margin for better spacing */
}

.toggle-button {
  background: none;
  border: 2px solid #800020;
  color: #800020;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.toggle-button:first-child {
  border-radius: 10px 0 0 10px;
}

.toggle-button:last-child {
  border-radius: 0 10px 10px 0;
}

.toggle-button.active {
  background: #800020;
  color: white;
}

.crop-container {
  width: 100%; /* Make sure it takes the full width of the modal */
  height: calc(100% - 60px); /* Subtract the height of the buttons */
  position: relative;
}

.crop-button, .cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  flex-grow: 1;
  margin: 0 5px; /* Add margin between buttons */
}

.crop-button {
  background-color: #28a745;
  color: white;
}

.cancel-button {
  background-color: #800020;
  color: white;
}

@media (max-width: 600px) {
  .crop-button, .cancel-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}




.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  opacity: 0.7; /* Slight transparency */
  transition: opacity 0.3s ease;
}

.password-toggle-icon:hover {
  opacity: 1; /* Fully opaque on hover */
}


.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
}

.login-link a {
  color: #800020;
  text-decoration: none;
  font-weight: 600;
}

.login-link a:hover {
  text-decoration: underline;
}

.checkbox-group label {
  margin-right: 10px; /* Adds space between each checkbox group */
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px; /* Adds space between the checkbox and the label text */
}



.custom-results-list {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  max-height: 400px;
  overflow-y: auto;
}

.custom-results-item {
  font-size: 16px;
}

.form-radio-group {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  /* margin-left: 20px; */
}

.radio-group label {
 
  align-items: center;
  margin-left: 5px; /* Adds a little gap between each label */
}

.radio-group input[type="radio"] {
  margin-right: 3px; /* Adds space between the radio button and the label text */
}

.multi-select {
  position: relative;
  width: 100%;
}

.multi-select-dropdown {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

.multi-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 2px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 10px 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.multi-select-option {
  padding: 10px 15px;
  cursor: pointer;
}

.multi-select-option:hover {
  background-color: #f0f0f0;
}

.multi-select-option.selected {
  background-color: #800020;
  color: white;
}

.dots {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 10px;
}

.dots div {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.dots div:nth-child(1) {
  left: 8px;
  animation: dots1 0.6s infinite;
}

.dots div:nth-child(2) {
  left: 8px;
  animation: dots2 0.6s infinite;
}

.dots div:nth-child(3) {
  left: 32px;
  animation: dots2 0.6s infinite;
}

.dots div:nth-child(4) {
  left: 56px;
  animation: dots3 0.6s infinite;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-preview {
  position: relative;
  display: inline-block;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.text-danger {
  color: #800020;
  font-weight: 600; /* Increased font weight */
}
.custom-file-label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 5px;
  color: #666;
  position: relative;
  
  width: 100%; /* Adjust to your needs */
}

.upload-icon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.custom-file-label input[type="file"] {
  position: absolute;
  top: 0;
  
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label::after {
  content: ""; /* Ensure no text is displayed after the label */
}

.file-count {
  font-size: 1rem;
  color: #666;
  margin-bottom: 5px;
  color: #800020;
}

.text-danger {
  color: #800020;
  font-weight: 600;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between the preview boxes */
}

.image-preview-box {
  width: 80px; /* Fixed width for each box */
  height: 80px; /* Fixed height for each box */
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc; /* Optional: Border for the boxes */
  border-radius: 5px; /* Optional: Rounded corners */
  overflow: hidden; /* Ensure images fit within the box */
}

.image-preview {
  width: 100%; /* Make sure the image covers the box width */
  height: 100%; /* Make sure the image covers the box height */
  object-fit: cover; 
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}




@keyframes dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
