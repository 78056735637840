@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');

.login-page * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login-page {
    font-family: 'Raleway', sans-serif;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 100vh;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

.login-container {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 20px;
}

.login-card {
  background: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.login-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-field {
  position: relative;
}

.form-label {
  position: absolute;
  left: 15px;
  top: 13px;
  color: #999;
  font-size: 1rem;
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #800020;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  background-color: white;
  padding: 0 5px;
  color: #800020;
}

.error-message {
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 5px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #800020, #800020c1);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  opacity: 0.7;
}

.submit-button.loading {
  cursor: not-allowed;
  opacity: 1;
}



.submit-button:hover:not(.loading) {
  background: linear-gradient(45deg, #800020, #800020c1);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.toggle-button {
  background: none;
  border: 2px solid #800020d6;
  color: #800020;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.toggle-button:first-child {
  border-radius: 10px 0 0 10px;
}

.toggle-button:last-child {
  border-radius: 0 10px 10px 0;
}

.toggle-button.active {
  background: #800020;
  color: white;
}

.remember-me {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}

.remember-me input {
  margin-right: 5px;
}

.password-toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
}

.register-link a {
  color: #800020;
  text-decoration: none;
  font-weight: 600;
}

.register-link a:hover {
  text-decoration: underline;
}

@keyframes oliveLoader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.loader {
  display: flex;
  align-items: center;
}

.loader div {
  width: 10px;
  height: 10px;
  background-color: white; /* Change color to white */
  border-radius: 50%;
  margin: 0 5px;
  animation: oliveLoader 0.6s infinite ease-in-out;
}

.loader div:nth-child(1) {
  animation-delay: -0.2s;
}

.loader div:nth-child(2) {
  animation-delay: -0.1s;
}

.loader div:nth-child(3) {
  animation-delay: 0s;
}
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.login-card {
  animation: fadeIn 0.5s ease-out;
}