.custom-calendar {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: #f9f9f9; /* Background color of the calendar */
}

/* Navigation bar styling */
.custom-calendar .react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #800020 !important; /* Background color of the navigation bar */
  color: white !important; /* Text color of the navigation bar */
  padding: 15px 10px; /* Increase the padding to make the bar taller */
  border-radius: 10px 10px 0 0;
  height: 70px; /* Explicitly set the height to increase the bar's height */
}

/* Navigation buttons */
.custom-calendar .react-calendar__navigation button {
  color: white !important; /* Text color for navigation buttons */
  background-color: transparent !important; /* Make button background transparent */
  border: none !important; /* Remove the border */
  font-size: 24px !important; /* Increase the font size for larger arrows */
  padding: 15px 25px !important; /* Increase padding to make the buttons larger */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px; /* Add some rounding to the buttons */
}

.custom-calendar .react-calendar__navigation button:focus {
  outline: none !important; /* Remove the focus outline */
  box-shadow: none !important; /* Remove any box-shadow that might be applied */
}


.custom-calendar .react-calendar__navigation button svg {
  width: 24px; /* Increase the width of the SVG arrows */
  height: 24px; /* Increase the height of the SVG arrows */
}

.custom-calendar .react-calendar__navigation button:enabled:hover,
.custom-calendar .react-calendar__navigation button:enabled:focus {
  background-color: #560016 !important; /* Darker shade on hover */
}

/* Weekdays styling */
.custom-calendar .react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: #f1f1f1;
  color: #333 !important; /* Color of the weekday names */
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

/* Day tiles styling */
.custom-calendar .react-calendar__tile {
  flex-grow: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 2px;
  background-color: #ffffff !important; /* Background color of each day tile */
  color: #333 !important; /* Text color of each day */
  border: 1px solid transparent; /* Add a border to improve structure */
}

.custom-calendar .react-calendar__tile:enabled:hover,
.custom-calendar .react-calendar__tile:enabled:focus {
  background-color: #560016 !important; 
  color: white !important;
  border-color: #800020 !important; /* Add border color on hover */
}

/* Selected day */
.custom-calendar .react-calendar__tile--active {
  background-color: #800020 !important; /* Background color of the selected day */
  color: white !important; /* Text color of the selected day */
  border-color: #560016 !important;
}

/* Current day */
.custom-calendar .react-calendar__tile--now {
  background-color: #FFD700 !important; /* Background color of the current day */
  color: black !important; /* Text color of the current day */
  border-color: #FFA500 !important; /* Border color for the current day */
}

/* Year, Decade, and Century View Tile Styles */
.custom-calendar .react-calendar__year-view__months,
.custom-calendar .react-calendar__decade-view__years,
.custom-calendar .react-calendar__century-view__decades {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important; /* Ensure 3 columns layout */
  gap: 10px; /* Add space between tiles */
}

.custom-calendar .react-calendar__year-view__tile,
.custom-calendar .react-calendar__decade-view__tile,
.custom-calendar .react-calendar__century-view__tile {
  border-radius: 8px !important; /* Rounding the corners */
  background-color: #ffffff !important; /* Default background color */
  color: #333 !important; /* Default text color */
  margin: 2px;
  border: 1px solid transparent; /* Add a border to improve structure */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.custom-calendar .react-calendar__year-view__tile:hover,
.custom-calendar .react-calendar__decade-view__tile:hover,
.custom-calendar .react-calendar__century-view__tile:hover {
  background-color: #560016 !important; /* Hover background color */
  color: white !important; /* Hover text color */
  border-color: #800020 !important; /* Add border color on hover */
}

.custom-calendar .react-calendar__year-view__tile--active,
.custom-calendar .react-calendar__decade-view__tile--active,
.custom-calendar .react-calendar__century-view__tile--active {
  background-color: #800020 !important; /* Active background color */
  color: white !important; /* Active text color */
  border-color: #560016 !important;
}
